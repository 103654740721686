const downloadBlob = ({ type, filename, content }) => {
  const blob = new Blob([content], {
    type,
  });

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export default downloadBlob;
